import React from "react";
import { LoadingMask, NonIdealState, Spinner } from "@remhealth/ui";
import { ErrorBoundary } from "~/error-handling";
import { FullCenterScreen } from "../styles";

const loading = (
  <FullCenterScreen>
    <NonIdealState icon={<Spinner intent="primary" size={64} />} />
  </FullCenterScreen>
);

const loadingMask = <LoadingMask />;

export interface SuspenseProps {
  /** If true, the loading mask will be full screen */
  overlay?: boolean;

  /** If true, no loading mask will appear */
  minimal?: boolean;
}

export function Suspense(props: React.PropsWithChildren<SuspenseProps>) {
  const { children, minimal = false, overlay = false } = props;

  const fallback = minimal ? undefined : overlay ? loadingMask : loading;
  return (
    <ErrorBoundary>
      <React.Suspense fallback={fallback}>{children}</React.Suspense>
    </ErrorBoundary>
  );
}
