import { createRoot } from "react-dom/client";
import { lazy } from "@remhealth/ui";
import { Suspense } from "@remhealth/core";
import { isBrowser, isBrowserNotSupported, isMobileOnly } from "@remhealth/ai";

const App = lazy(() => import("./app"));
const RestrictedMobileView = lazy(() => import("./restrictedMobileView"));
const BrowserNotSupported = lazy(() => import("./browserNotSupported"));

import "./theme.scss";

let rootElement:JSX.Element;
if (isMobileOnly) {
  rootElement = <Suspense minimal><RestrictedMobileView /></Suspense>;
} else if (isBrowser && isBrowserNotSupported()) {
  rootElement = <Suspense minimal><BrowserNotSupported /></Suspense>;
} else {
  rootElement = <Suspense minimal><App /></Suspense>;
}

const root = createRoot(document.getElementById("root")!);
root.render(rootElement);
