import { createPathIcon } from "./pathIcon";
import { createSvgIcon } from "./svgIcon";

// Blueprint Path Icons
export const Add = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/add"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/add"));
export const ArrowBottomLeft = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/arrow-bottom-left"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/arrow-bottom-left"));
export const ArrowBottomRight = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/arrow-bottom-right"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/arrow-bottom-right"));
export const ArrowDown = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/arrow-down"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/arrow-down"));
export const ArrowLeft = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/arrow-left"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/arrow-left"));
export const ArrowRight = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/arrow-right"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/arrow-right"));
export const ArrowTopLeft = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/arrow-top-left"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/arrow-top-left"));
export const ArrowTopRight = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/arrow-top-right"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/arrow-top-right"));
export const ArrowUp = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/arrow-up"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/arrow-up"));
export const Asterisk = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/asterisk"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/asterisk"));
export const AutomaticUpdates = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/automatic-updates"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/automatic-updates"));
export const Blank = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/blank"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/blank"));
export const BlockedPerson = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/blocked-person"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/blocked-person"));
export const Build = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/build"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/build"));
export const Bold = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/bold"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/bold"));
export const BringData = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/bring-data"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/bring-data"));
export const Calculator = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/calculator"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/calculator"));
export const CaretDown = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/caret-down"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/caret-down"));
export const CaretLeft = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/caret-left"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/caret-left"));
export const CaretRight = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/caret-right"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/caret-right"));
export const CaretUp = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/caret-up"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/caret-up"));
export const Clean = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/clean"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/clean"));
export const Chain = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/link"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/link"));
export const Chart = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/chart"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/chart"));
export const ChevronDown = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/chevron-down"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/chevron-down"));
export const ChevronLeft = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/chevron-left"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/chevron-left"));
export const ChevronRight = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/chevron-right"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/chevron-right"));
export const ChevronUp = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/chevron-up"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/chevron-up"));
export const Clipboard = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/clipboard"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/clipboard"));
export const Cloud = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/cloud"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/cloud"));
export const CloudDownload = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/cloud-download"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/cloud-download"));
export const CloudUpload = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/cloud-upload"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/cloud-upload"));
export const Comment = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/comment"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/comment"));
export const Cog = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/cog"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/cog"));
export const Cross = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/cross"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/cross"));
export const CrossCircle = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/cross-circle"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/cross-circle"));
export const Database = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/database"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/database"));
export const Disable = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/disable"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/disable"));
export const Dollar = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/dollar"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/dollar"));
export const Dot = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/dot"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/dot"));
export const DoubleCaretVertical = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/double-caret-vertical"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/double-caret-vertical"));
export const DoubleChevronDown = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/double-chevron-down"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/double-chevron-down"));
export const DoubleChevronLeft = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/double-chevron-left"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/double-chevron-left"));
export const DoubleChevronRight = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/double-chevron-right"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/double-chevron-right"));
export const DoubleChevronup = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/double-chevron-up"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/double-chevron-up"));
export const DragHandleVertical = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/drag-handle-vertical"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/drag-handle-vertical"));
export const Edit = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/edit"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/edit"));
export const Error = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/error"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/error"));
export const Exchange = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/exchange"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/exchange"));
export const Export = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/export"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/export"));
export const EyeOff = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/eye-off"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/eye-off"));
export const EyeOn = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/eye-on"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/eye-on"));
export const EyeOpen = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/eye-open"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/eye-open"));
export const Film = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/film"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/film"));
export const Filter = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/filter"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/filter"));
export const FilterKeep = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/filter-keep"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/filter-keep"));
export const FilterList = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/filter-list"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/filter-list"));
export const FilterOpen = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/filter-open"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/filter-open"));
export const FilterRemove = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/filter-remove"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/filter-remove"));
export const Flag = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/flag"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/flag"));
export const FloppyDisk = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/floppy-disk"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/floppy-disk"));
export const FolderOpen = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/folder-open"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/folder-open"));
export const Form = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/form"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/form"));
export const Generate = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/generate"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/generate"));
export const Geosearch = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/geosearch"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/geosearch"));
export const GitPull = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/git-pull"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/git-pull"));
export const GridView = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/grid-view"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/grid-view"));
export const Header = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/header"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/header"));
export const Help = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/help"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/help"));
export const HorizontalInbetween = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/horizontal-inbetween"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/horizontal-inbetween"));
export const Import = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/import"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/import"));
export const InheritedGroup = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/inherited-group"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/inherited-group"));
export const InfoSign = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/info-sign"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/info-sign"));
export const Issue = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/issue"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/issue"));
export const Italic = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/italic"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/italic"));
export const Label = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/label"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/label"));
export const List = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/list"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/list"));
export const Lock = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/lock"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/lock"));
export const LogOut = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/log-out"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/log-out"));
export const Maximize = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/maximize"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/maximize"));
export const Menu = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/menu"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/menu"));
export const Minus = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/minus"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/minus"));
export const Minimize = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/minimize"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/minimize"));
export const Modal = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/modal"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/modal"));
export const More = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/more"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/more"));
export const Nest = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/nest"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/nest"));
export const NewPerson = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/new-person"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/new-person"));
export const Notifications = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/notifications"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/notifications"));
export const NotificationsSnooze = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/notifications-snooze"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/notifications-snooze"));
export const NumberedList = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/numbered-list"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/numbered-list"));
export const Numerical = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/numerical"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/numerical"));
export const Office = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/office"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/office"));
export const Offline = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/offline"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/offline"));
export const Outdated = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/outdated"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/outdated"));
export const Paragraph = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/paragraph"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/paragraph"));
export const Pause = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/pause"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/pause"));
export const Percentage = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/percentage"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/percentage"));
export const Person = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/person"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/person"));
export const Plus = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/plus"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/plus"));
export const Print = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/print"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/print"));
export const Properties = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/properties"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/properties"));
export const Refresh = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/refresh"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/refresh"));
export const Repeat = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/repeat"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/repeat"));
export const Reset = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/reset"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/reset"));
export const Search = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/search"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/search"));
export const SegmentedControl = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/segmented-control"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/segmented-control"));
export const SeriesSearch = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/series-search"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/series-search"));
export const SmallCross = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/small-cross"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/small-cross"));
export const SmallTick = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/small-tick"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/small-tick"));
export const Star = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/star"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/star"));
export const StarEmpty = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/star-empty"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/star-empty"));
export const Stop = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/stop"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/stop"));
export const Stopwatch = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/stopwatch"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/stopwatch"));
export const Strikethrough = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/strikethrough"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/strikethrough"));
export const Style = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/style"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/style"));
export const Tag = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/tag"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/tag"));
export const TextHighlight = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/text-highlight"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/text-highlight"));
export const Tick = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/tick"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/tick"));
export const TickCircle = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/tick-circle"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/tick-circle"));
export const Time = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/time"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/time"));
export const Translate = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/translate"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/translate"));
export const Trash = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/trash"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/trash"));
export const Underline = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/underline"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/underline"));
export const Undo = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/undo"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/undo"));
export const Updated = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/updated"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/updated"));
export const User = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/user"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/user"));
export const WarningSign = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/warning-sign"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/warning-sign"));
export const ZoomOut = /* @__PURE__ */ createPathIcon(() => import("@blueprintjs/icons/lib/esm/generated/20px/paths/zoom-out"), () => import("@blueprintjs/icons/lib/esm/generated/16px/paths/zoom-out"));

// Custom SVG Icons
export const Account = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/account.svg"));
export const AddToDictionary = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/add-to-dictionary.svg"));
export const Agenda = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/agenda.svg"));
export const Archive = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/archive.svg"));
export const Book = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/book.svg"));
export const BulkSign = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/bulk-sign.svg"));
export const Calendar = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/calendar.svg"));
export const Celebrate = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/celebrate.svg"));
export const CheckboxBordered = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/checkbox-bordered.svg"));
export const Clients = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/clients.svg"));
export const CloneLight = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/clone-light.svg"));
export const Confidential = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/confidential.svg"));
export const Confirm = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/confirm.svg"));
export const DataRecovery = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/data-recovery.svg"));
export const PersonTree = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/person-tree.svg"));
export const DockLeft = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/dock-left.svg"));
export const DockRight = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/dock-right.svg"));
export const DocRouting = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/doc-routing.svg"));
export const DocumentFile = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/document-file.svg"));
export const DocumentScan = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/document-scan.svg"));
export const Demographics = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/demographics.svg"));
export const Download = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/download.svg"));
export const Envelope = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/envelope.svg"));
export const Group = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/group.svg"));
export const InfoSignCircle = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/info-sign-circle.svg"));
export const LengthenText = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/lengthen-text.svg"), () => import("./svg-icons/lengthen-text-16.svg"));
export const Link = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/link.svg"));
export const LockOutline = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/lock-outline.svg"));
export const Mention = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/mention.svg"));
export const Microphone = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/microphone.svg"));
export const Minor = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/minor.svg"));
export const MoreVertical = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/more-vertical.svg"));
export const NarrativeLink = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/narrative-link.svg"));
export const NotConnected = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/not-connected.svg"));
export const OrderedList = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/ordered-list.svg"));
export const ParagraphText = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/paragraph-text.svg"));
export const PatientChart = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/patient-chart.svg"));
export const PatientInfo = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/patient-info.svg"));
export const Presentation = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/presentation.svg"));
export const Preview = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/preview.svg"));
export const RadioButton = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/radio-button.svg"));
export const RankedList = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/ranked-list.svg"));
export const RedoAi = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/redo-ai.svg"));
export const ReminderAlert = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/reminder-alert.svg"));
export const RestoreBox = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/restore-box-up-light.svg"));
export const ResetLight = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/reset-light.svg"));
export const Scribbles = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/scribbles.svg"));
export const Session = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/session.svg"));
export const Share = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/share.svg"));
export const ShortenText = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/shorten-text.svg"), () => import("./svg-icons/shorten-text-16.svg"));
export const Snippet = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/snippet.svg"));
export const Split = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/split.svg"));
export const SplitBranch = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/split-branch.svg"));
export const Support = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/support.svg"));
export const SyncIssue = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/sync-issue.svg"));
export const Text = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/text.svg"));
export const TextAi = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/text-ai.svg"));
export const TextExpansions = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/text-expansions.svg"));
export const Todo = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/todo.svg"));
export const Transcription = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/transcription.svg"));
export const UnorderedList = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/unordered-list.svg"));
export const Writing = /* @__PURE__ */ createSvgIcon(() => import("./svg-icons/writing.svg"));
