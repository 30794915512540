import { lazy } from "@remhealth/ui";

export type { AppProps } from "./app/app";
export type { AboutBellsProps } from "./settings/about/aboutBells";

export * from "./app/browser";
export * from "./app/profile";
export * from "./app/routes";
export * from "./config";
export * from "./contexts";
export * from "./audit";
export * from "./patients";
export * from "./reports";
export * from "./supervision";
export * from "./theme";
export * from "./update";

export const App = lazy(() => import("./app/app"));

export { Text } from "./text";
